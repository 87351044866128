import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "21 年新卒入社エンジニアと座談会で振り返る新卒研修",
  "date": "2022-07-22T12:48:13.000Z",
  "slug": "entry/2022/07/22/214813",
  "tags": ["medley"],
  "hero": "./2022_07_22.png",
  "heroAlt": "FY21 engineer"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの新居です。`}</p>
    <p>{`今回は 2021 年新卒入社のエンジニア 5 人に対し、`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2021/10/12/180147"
      }}>{`新卒研修`}</a>{`を終えてからこれまでにプロダクト開発業務を進めている中で、どのように新卒研修が活きているのかを振り返ってもらおうという座談会の様子をお送りしようと思います。`}</p>
    <p>{`今までブログでお伝えしてきた新卒研修はメンター側の立場で書かれていましたが、当時メンティーだった 2021 年新卒メンバーの紹介をしながら、初めての試みとしてメンティー側はどのようなことを当時考えていたのかをお伝えできればと思います。`}</p>
    <h1>{`2021 年新卒入社メンバー紹介`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4f0bfb6347b64ae8d8d477f0801177f9/cdb2b/image_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABTSldSYOK/8QAGxAAAQUBAQAAAAAAAAAAAAAAAgEDBBExABP/2gAIAQEAAQUCSQVm5R+rqqWS+az/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIhEjFxgf/aAAgBAQAGPwK6IKVJoeCWx+kB9P/EABwQAQABBAMAAAAAAAAAAAAAAAERABAhMWFx0f/aAAgBAQABPyFpYDI5oWJPLNDGHwtG3Xy3f//aAAwDAQACAAMAAAAQn8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Qiv/EAB4QAQADAAICAwAAAAAAAAAAAAEAESExYVGRobHB/9oACAEBAAE/EApOuFm631kZ/W1KfB8RHAoVTvvMrITjOfxANpeoz3P6J//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "集合写真",
            "title": "集合写真",
            "src": "/static/4f0bfb6347b64ae8d8d477f0801177f9/e5166/image_003.jpg",
            "srcSet": ["/static/4f0bfb6347b64ae8d8d477f0801177f9/f93b5/image_003.jpg 300w", "/static/4f0bfb6347b64ae8d8d477f0801177f9/b4294/image_003.jpg 600w", "/static/4f0bfb6347b64ae8d8d477f0801177f9/e5166/image_003.jpg 1200w", "/static/4f0bfb6347b64ae8d8d477f0801177f9/cdb2b/image_003.jpg 1281w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`(左から)筆者 / 内田さん / 高橋さん / 堀内さん / 佐々岡さん / 寺内さん`}</p>
    </blockquote>
    <h2>{`高橋さん`}</h2>
    <p>{`医療プラットフォーム第一本部 プロダクト開発室 第二開発グループ所属。CLINICS の開発を担当。現在は周辺領域拡張プロジェクトに携わる。`}</p>
    <h2>{`佐々岡さん`}</h2>
    <p>{`人材プラットフォーム本部 プロダクト開発室 第一開発グループ所属。ジョブメドレーの開発を担当。現在は求職者側の UI/UX 改善などの開発に携わる。`}</p>
    <h2>{`寺内さん`}</h2>
    <p>{`人材プラットフォーム本部 プロダクト開発室 第三開発グループ所属。介護のほんねの開発を担当。定常開発や新機能開発に携わる。`}</p>
    <h2>{`堀内さん`}</h2>
    <p>{`人材プラットフォーム本部 プロダクト開発室 第一開発グループ所属。ジョブメドレーの開発を担当。現在は求職者側の UI/UX 改善などの開発に携わる。`}</p>
    <h2>{`内田さん`}</h2>
    <p>{`医療プラットフォーム第一本部 プロダクト開発室 第四開発グループ所属。CLINICS の開発を担当。現在は CLINICS カルテの基幹システムの開発に携わる。`}</p>
    <h1>{`チームの雰囲気について`}</h1>
    <p><em parentName="p">{`メドレー入社後 1 年が経ち、5 人の皆さんはどのような雰囲気のチームで働いているかを話してもらいました。`}</em></p>
    <p><strong parentName="p">{`高橋`}</strong>{`: CLINICS の中でもうちのチームは`}<strong parentName="p">{`出社頻度が高めで対面でコミュニケーションを取る機会が多い`}</strong>{`ですね。新規機能を中心に開発しているので、細かな調整がしやすくスピード感を持って開発できています。`}</p>
    <p><strong parentName="p">{`内田`}</strong>{`: 自分は別のチームなんですが、夕会をスタンディングでやっているのを見てカッコいいなと思っていて羨しいです w`}</p>
    <p><strong parentName="p">{`佐々岡`}</strong>{`:
ジョブメドレーチームの雰囲気としては結構リモートで開発している方の割合が多めです。`}<strong parentName="p">{`家庭持ちの方とかは家で実装していたりすることが多い`}</strong>{`です。デザイナーとエンジニアはすごく近い距離で仕事をしています。 その中で自分は、主に求職者の応募体験を良くするための改善などを行なっています。`}</p>
    <p><strong parentName="p">{`寺内`}</strong>{`: 介護のほんねは、プロダクト開発チームがコンパクトなので他のみんなと違い明確に担当が分かれているということはないです。自分は社内ツールの業務改善系の開発を担当することが多いです。`}</p>
    <p>{`社内ツールの非効率な部分を改善したり、定常業務で必要になるシステム改善や修正を主に手がけています。
サーバーサイドの開発が主で、必要に応じてフロントエンドの開発も行なっています。チームの雰囲気は、`}<strong parentName="p">{`締めるところは締める雰囲気`}</strong>{`です。MTG なども少なめなので、実装や考えることに時間を使っていける環境です。`}</p>
    <p><strong parentName="p">{`佐々岡`}</strong>{`: 寺内はチームのプロダクト責任者が直接メンタリングしてくれるのが良いなーと思っています。`}</p>
    <p><strong parentName="p">{`寺内`}</strong>{`: そうですね。`}<strong parentName="p">{`直属の上司にレビューを含め、メンタリングなどもしていただいている`}</strong>{`ので、ありがたいです。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 自分も佐々岡と一緒でジョブメドレーの開発を担当しています。自分が思うチームの雰囲気ですが、「温和なチーム」と感じています。 心理的安全性が高いチームですね。`}</p>
    <p>{`自分が今担当しているのは求職者側の UI/UX 改善であったり、ページのパフォーマンス改善などです。開発の特徴としては、開発案件を進めるときに`}<strong parentName="p">{`すぐにプロダクトマネージャやリードエンジニアといった方々と会話して進めていける`}</strong>{`部分でしょうか。`}</p>
    <p>{`ジョブメドレー自体、サービスとしては 10 年選手です。ですから、業務フローが大変成熟したものになっています。
`}<strong parentName="p">{`洗練された企画・開発フローを実際に体感しながら開発できる、という環境がとても勉強になります`}</strong>{`ね。`}</p>
    <p>{`またこのフローは`}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`ドキュメントドリブン`}</a>{`という考え方で作られた資料に基いていますので、後から蓄積された知見をキャッチアップすることも容易ですし、リモートの人と出社している人との間で齟齬が起きることなく仕事が進められています。`}</p>
    <p><strong parentName="p">{`内田`}</strong>{`: CLINICS 電子カルテの基盤チームで開発しています。基盤チームでは本当にこれぞカルテという部分を扱っているので、レセプトに関係する部分なども担当しています。`}</p>
    <p>{`チームの特徴としては、チーム構成として`}<strong parentName="p">{`デザイナーやディレクターといった職種の方達がいるんですが、彼らと一緒に会話をしながら、開発を進めていく`}</strong>{`ということでしょうか。`}</p>
    <p>{`職種で分けずに全員で認識を揃えながらの開発ができています。あとはメドレーのデザイナーは多かれ少なかれ全員そうだと思うのですが、特に今のチームのデザイナーは自分でフロントエンドの `}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/"
      }}>{`React.js`}</a>{` のコードを書いているので、手戻りなども無くすごく仕事がやりやすい環境です。こうした環境で自分が要件から考えて実装していくという開発をできているのが、とてもやりがいがあります。`}</p>
    <p><em parentName="p">{`それぞれ配属されているチームが違うこともあり、チームの雰囲気などはやはり色々と違うようです。しかし、共通してチームで動きながら職種に囚われずに要件定義から関わって開発をしているというところは、全員が感じているようですね。`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/986da7af2eef3c47ecae6731dc971a0a/cdb2b/image_014.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMEBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAXPzj3NgQSf/xAAbEAACAgMBAAAAAAAAAAAAAAABAgADBBMyM//aAAgBAQABBQI5BFu4LBezQetkXn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAABAwUAAAAAAAAAAAAAAAAAARIhAhBhgZH/2gAIAQEABj8CbXGBXLwg3f8A/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUeH/2gAIAQEAAT8h0AEynGVgB66YdbsMt8xqbb+5ko5cyKn/2gAMAwEAAgADAAAAEHAP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EJiv/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QR//EABoQAQEAAwEBAAAAAAAAAAAAAAERACFBMXH/2gAIAQEAAT8QvCXjreV+ZGeA3Hhuh3uU6ZaAo7JjI1b1twAQDgedxIUhOZ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "全景",
            "title": "全景",
            "src": "/static/986da7af2eef3c47ecae6731dc971a0a/e5166/image_014.jpg",
            "srcSet": ["/static/986da7af2eef3c47ecae6731dc971a0a/f93b5/image_014.jpg 300w", "/static/986da7af2eef3c47ecae6731dc971a0a/b4294/image_014.jpg 600w", "/static/986da7af2eef3c47ecae6731dc971a0a/e5166/image_014.jpg 1200w", "/static/986da7af2eef3c47ecae6731dc971a0a/cdb2b/image_014.jpg 1281w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`今までのプログラミング経験`}</h1>
    <p><em parentName="p">{`次の話題として、21 年入社メンバーの入社前のプログラミング経験について聞いてみました。みなさんどんな形でプログラミングに関わってきたんでしょうか?`}</em></p>
    <p><strong parentName="p">{`高橋`}</strong>{`: プログラミングを始めたのは高校 1 年生の頃からです。`}<strong parentName="p">{`中学生のころからコンピュータに触れはじめて漠然とプログラミングの勉強がしたい`}</strong>{`という思いから、情報系学科に入学してゲームプログラミングから始めたのが最初です。`}</p>
    <p>{`大学時代は、実践型のプロジェクト学習に力を入れている公立はこだて未来大学で様々な開発経験を積みました。学部 3 年生の 1 年間は、4 大学合同でチーム開発をするという講義の中で iOS アプリケーションの開発を経験しました。`}</p>
    <p>{`学部 4 年生からは、都内にある SIer 企業の社内システムを開発する学内プロジェクトに参加しました。他にもエンジニアとして働けるサマーインターンにも参加しました。`}</p>
    <p><strong parentName="p">{`内田`}</strong>{`: 自分達と同世代だと中学生のときくらいに、例えば PSP を始めとした`}<strong parentName="p">{`ゲーム機だとか、家のパソコンを触っていくことをきっかけにして、プログラミングに興味を持つ`}</strong>{`という人が多い印象です。この 5 人の共通の思い出として、やっぱりこの辺の話題が出てきたりします。`}</p>
    <p><strong parentName="p">{`佐々岡`}</strong>{`: 自分が`}<strong parentName="p">{`プログラミングを始めたのは、情報系の大学に入ってから`}</strong>{`でした。始めてからは `}<a parentName="p" {...{
        "href": "https://rubyonrails.org/"
      }}>{`Ruby on Rails`}</a>{` での開発や `}<a parentName="p" {...{
        "href": "https://www.python.org/"
      }}>{`Python`}</a>{` の `}<a parentName="p" {...{
        "href": "https://www.djangoproject.com/"
      }}>{`Django`}</a>{` での開発をアルバイトで経験を積んでいきました。サークルでも PHP を使った Web アプリケーションを作ったりと、のめり込んでいました。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 自分は大学では情報系学科ではなく、経済学部を専攻しました。高校のときは理系コースで「理系の大学に行くんだ」と頑張っていたんですが、どちらかというと文系科目の方が好きだったので、数学の知識も使いつつ文系である経済学を選んだ形です。`}</p>
    <p>{`プログラミングの原体験は Web になります。もうサービスは無くなってしまったんですが、`}<strong parentName="p">{`中学生のときに Yahoo!ジオシティーズというホームページを簡単に作れるというサービスに夢中になった`}</strong>{`時期がありました。`}</p>
    <p>{`このサービスはエディタにテキストを入れると HTML が生成されるというシステムだったんで、そこでこういった感じで Web が出来るんだなと思い面白いなと思ったのがきっかけです。そこから色を変えたいから CSS を勉強する、クジ引きアプリ作りたいから JavaScript を勉強するみたいな感じで、のめり込んでいきました。`}</p>
    <p>{`中高くらいにスマホが出てきたんですが、当時カスタムされた OS が流通していて、そういうのも面白いと触っていました。同時期にブログを運営していたのでその時に `}<a parentName="p" {...{
        "href": "https://www.php.net/"
      }}>{`PHP`}</a>{` での開発をしていきました。一通り Web プログラミングが出来るようになってからは、個人事業主として開発や保守を受託することが多く、その時のお仕事で、フロント / バックエンド / インフラ 満遍なく貴重な経験をさせて頂けたと思います。`}</p>
    <p><strong parentName="p">{`内田`}</strong>{`: 僕は`}<strong parentName="p">{`小学校 3~4 年生くらいに最初にプログラミングに触れました`}</strong>{`。当時、宇宙がすごく好きでちょうど `}<a parentName="p" {...{
        "href": "https://www.jaxa.jp/"
      }}>{`JAXA`}</a>{` が小学生向けのプロジェクトやっていてそれがロボットの開発をするというものだったんです。そのロボットの制御をするためにプログラミングが必要になって触り始めたのが最初です。`}</p>
    <p>{`中学生で Web プログラミングに触れまして。ちょうど YouTube が盛り上るくらいのタイミングだったんで、今でいう YouTuber っぽい活動していてその宣伝のためのホームページを作るためにサイトを作ったりしていました。`}</p>
    <p>{`でも、そこからはあんまりプログラミングに触れていなくて、大学 2 年生くらいになって部活でアプリ開発をやり始めて、それがスケールアップして起業もしました。`}</p>
    <p>{`その会社は儲からなくて閉じたんですが、そこからある程度プログラミング経験が積めたなと思ったので、医療系を含む、色々な企業でインターンとしてプログラミングをしていました。そこからメドレーに入社しました。`}</p>
    <p><strong parentName="p">{`寺内`}</strong>{`: 僕も佐々岡と一緒で大学に入ってからでした。高校のときは遺伝子に興味があったんですが、Web 広告で`}<strong parentName="p">{`プログラマーについて知ってこっちの方がより面白そうだと思って情報系学科に入る`}</strong>{`ことにしました。`}</p>
    <p>{`サークルで知りあった人にインターンをおすすめされたので、1 年生の後半あたりからずっとインターンとしてプログラミングをしていました。その中にメドレーもありました。`}</p>
    <p><em parentName="p">{`話を聞くと皆さん大分早い段階からプログラミングに興味を持ち出していますね。大学でインターンを有効活用してスキルアップしてきた人も多いです。最近あまり言わないですが、デジタルネイティブという印象です。`}</em></p>
    <h1>{`メドレーに入社を決めた理由`}</h1>
    <p><em parentName="p">{`そうした経験を積んできた 5 人はどんな理由でメドレーの入社を決めたのかが気になるので、聞いてみました。`}</em></p>
    <p><strong parentName="p">{`高橋`}</strong>{`:
就活の軸として、社会的な課題を解決するプロダクトの開発に携わりたいという思いがありました。`}</p>
    <p>{`この軸は、大学時代までに個人開発やプロジェクトを通して開発してきた成果物が、思うような価値を生み出せなかったという悔しさが原体験としてあったためです。そこから課題解決が好きだったこともあり、エンジニアリングを通して大きな社会課題を解決したいという考えを持つようになりました。`}</p>
    <p>{`最終的には、インターンシップや就活を通して社員の方々から話を伺った中で「医療ヘルスケアの未来をつくる」という`}<strong parentName="p">{`メドレーのミッションに強く惹かれ`}</strong>{`入社を決めました。`}</p>
    <p><strong parentName="p">{`佐々岡`}</strong>{`: 生活のインフラに直結する分野の社会課題を解決するという所が良かったので、メドレーに入社しました。もう一つの理由としてはメドレーの「ドキュメントドリブン」という文化に心惹かれたからです。`}</p>
    <p>{`色々`}<strong parentName="p">{`社内の知見などをきちんとドキュメント化している`}</strong>{`ので、そういったドキュメントを参考にしていれば、自分の成長スピードも早くなるのではと思いました。`}</p>
    <p><strong parentName="p">{`内田`}</strong>{`: 他の人と同じで、医療という大きいドメインでの課題解決をしているという点はもちろんなんですが、働いている`}<strong parentName="p">{`エンジニアの方々が少数精鋭でレベルが高いなと感じた`}</strong>{`のも大きいです。`}</p>
    <p>{`そんな中で、新卒入社のエンジニアの人数もそこまで多くはないので、入社したらそんな方達と一緒に仕事ができるようになるなと感じたからです。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 就活の軸として、自分が成長できそうか・風通しが良いか・合理的な社風かなどを軸として探していました。将来的には経営をしたいと考えているので、`}<strong parentName="p">{`ビジネス側と開発側の距離が近い`}</strong>{`ことも考えて総合的にメドレーが良さそうだと考えて入社しました。`}</p>
    <p><strong parentName="p">{`寺内`}</strong>{`: 私はメドレーの夏の`}<strong parentName="p">{`インターンシップに参加したのですが、医療・ヘルスケアドメインの課題の多さと、それに正面から向き合うメドレーという会社が非常にエキサイティングだと感じた`}</strong>{`のが、きっかけです。`}</p>
    <p>{`そこから自分の医療の原体験というのを考えたときに、高校の時に親族が難病かもしれないという疑いが出てきたという出来事がありました。結局は大丈夫だったのですが、その時に医療情報などの探しにくさなどを実感したことを思い起しました。メドレーはそうした問題に取り組んでいる会社だというのが一番の理由でした。もちろん優秀な方が働いているというのもあります。`}</p>
    <p><em parentName="p">{`メドレーが「医療」という分野での事業をしているということに共感して入社している方が多いですね。共通して自分が作ったものが、社会にインパクトを生み出せるか?ということを意識しているメンバーが多いと思いました。`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/886d001e5862d0fc5dbef1cd9475f963/cdb2b/image_012.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAIA/9oADAMBAAIQAxAAAAGrOaThsNgf/8QAHBAAAQMFAAAAAAAAAAAAAAAAAwECMgAREjE0/9oACAEBAAEFAlgMLbNwHRucEl3/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPwG6j//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPwGYr//EABkQAQADAQEAAAAAAAAAAAAAAAEAAhCBQf/aAAgBAQAGPwLkSqnjESxnTP/EABoQAAMBAAMAAAAAAAAAAAAAAAABETEhcfD/2gAIAQEAAT8hROW4nFHryVLol0reK6bdDO/Gmh//2gAMAwEAAgADAAAAEEj/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAARIQH/2gAIAQMBAT8QxUKp/8QAFxEAAwEAAAAAAAAAAAAAAAAAABEhAf/aAAgBAgEBPxCmNSH/xAAdEAEAAwACAwEAAAAAAAAAAAABABEhUWExQXHh/9oACAEBAAE/ENGAXDKi0QwVWUtHr9gGtTHYNEiRRTpPMn79Tqzmf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image1",
            "title": "image1",
            "src": "/static/886d001e5862d0fc5dbef1cd9475f963/e5166/image_012.jpg",
            "srcSet": ["/static/886d001e5862d0fc5dbef1cd9475f963/f93b5/image_012.jpg 300w", "/static/886d001e5862d0fc5dbef1cd9475f963/b4294/image_012.jpg 600w", "/static/886d001e5862d0fc5dbef1cd9475f963/e5166/image_012.jpg 1200w", "/static/886d001e5862d0fc5dbef1cd9475f963/cdb2b/image_012.jpg 1281w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`2021 年度新卒研修の感想`}</h1>
    <p><em parentName="p">{`ここからは、新卒研修を受けた感想を 1 年越しに聞いてみたいと思います。特にチームとして開発をしていった「開発実践研修」について中心に聞いてみました。当時は大変なこともあったと思いますが、経験を積んだ今はどのような感想を持っているのでしょうか。`}</em></p>
    <h2>{`研修で良かったこと`}</h2>
    <p><strong parentName="p">{`佐々岡`}</strong>{`: 開発実践研修で良かったのはまず、`}<strong parentName="p">{`要件定義からリリースまで一気通貫にできたので自分のその時点での不足している部分というのが可視化されたこと`}</strong>{`でした。`}</p>
    <p>{`また開発するツールはそのまま社内で使い続けていくものだったので、レビュアーの方にも親身になってレビューしていただけたり、そういった所が良かった所です。`}</p>
    <p>{`開発実践では React.js を使って開発をしていたんですが、配属されてからも業務として使って活きている部分です。`}<strong parentName="p">{`メドレーで開発業務をするということが、どういうものかをこの研修を通じて勉強`}</strong>{`できました。 おかげで、配属された後も違和感なく実務ができるようになりました。`}</p>
    <p><strong parentName="p">{`高橋`}</strong>{`: エンジニアとしてこれからプロとして開発をしていく上で自信が付いたのが良かったです。`}</p>
    <p>{`学生のときは作って終わりというプロダクトが多かったのですが、`}<strong parentName="p">{`社会人になって初めて作ったプロダクトが今も毎日稼動しているということで、エンジニアとしての自信に繋がりました`}</strong>{`。`}</p>
    <p>{`この経験のおかげで、現在の業務でも自分が違和感などを感じたりしたときには、「間違ってるかな」などと臆せずにズバズバと言えるようになっています。`}</p>
    <p>{`現在のプロジェクトは要件の固まりきっていない新機能を開発しているフェーズなので、日々の業務に活かせている実感があります。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 今に繋がってるなと思っているのは`}<strong parentName="p">{`チームがちゃんと目標を持っているときと、そこがあやふやだったときのチームのパフォーマンスの出方が全然違うというのを体験`}</strong>{`できた点です。`}</p>
    <p>{`開発中は自分はチームリードとして、チームビルディングを中心に行っていたのですが、ちゃんと目標をイメージできる形にする・そのイメージをチームで認識が揃うまでしっかりと擦り合せる、という 2 点に注意していました。`}</p>
    <p>{`最初の目的をちゃんと話しあって固めた後は、朝会などでちゃんとメンバーの作業について共有していくようにしたら、上手くチームが回り始めました。最初にイメージを固めたら、MTG を小まめにしなくてもちゃんと作業が進んでいきました。`}</p>
    <p><strong parentName="p">{`寺内`}</strong>{`: 「開発実践研修」の前に受講した外部研修の「ビジネススタンス研修」が良かったです。研修の序盤に受けられて`}<strong parentName="p">{`社会人としてのスタンスが学べたのが良かった`}</strong>{`です。ここで習ったことが、開発実践研修でチームで話し合う場でもきちんと応用できましたし、もちろん業務をしている今でもちゃんと活きていると実感しています。研修の全メニューが良かったと感じているのは言うまでもないかもしれませんが w`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e35b9489f84cd02ac9acf1ded88bed8d/cdb2b/image_011.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAemF1mdxaD//xAAaEAEBAAIDAAAAAAAAAAAAAAACAQADBBM0/9oACAEBAAEFAte01pwt8eNT0LNtvZ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAHBABAAICAwEAAAAAAAAAAAAAAQARAhIhMUFz/9oACAEBAAY/Ak2W+ptbQ0xTKoR+ky59n//EABwQAAICAgMAAAAAAAAAAAAAAAABETEhQVFhcf/aAAgBAQABPyHekJIfHf0JYIe6ogwj5L+ZYlj/2gAMAwEAAgADAAAAEMQv/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARITH/2gAIAQMBAT8QdMpnl//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8QGwOdv//EABwQAQADAAMBAQAAAAAAAAAAAAEAESExQVFxof/aAAgBAQABPxAS3VhAehD2AA5NUHft8zQAaPHuYOAPHyJSVRv/AGGMpKC31n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image2",
            "title": "image2",
            "src": "/static/e35b9489f84cd02ac9acf1ded88bed8d/e5166/image_011.jpg",
            "srcSet": ["/static/e35b9489f84cd02ac9acf1ded88bed8d/f93b5/image_011.jpg 300w", "/static/e35b9489f84cd02ac9acf1ded88bed8d/b4294/image_011.jpg 600w", "/static/e35b9489f84cd02ac9acf1ded88bed8d/e5166/image_011.jpg 1200w", "/static/e35b9489f84cd02ac9acf1ded88bed8d/cdb2b/image_011.jpg 1281w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`研修で大変だったこと`}</h2>
    <p><strong parentName="p">{`佐々岡`}</strong>{`: 最初の段階での要件定義のときに課題設定が想定と違ってきたことが一番大変でした。`}</p>
    <p>{`研修企画側から予め提示されていた課題と自分達が考えた要件定義とのズレを、 `}<a parentName="p" {...{
        "href": "https://miro.com/ja/"
      }}>{`Miro`}</a>{` を使ってのミーティングを繰り返して修正するまでに、時間がかかってしまいました。そうしたミーティングなどで課題の解決方法などを構造化して考えたりしていき、ようやく修正できました。`}</p>
    <p>{`この経験で`}<strong parentName="p">{`最初の課題設定も正しいかどうかを鵜呑みにせずに、ちゃんと自分達で調べて納得するような形にしないといけない`}</strong>{`ということを学べました。`}</p>
    <p><strong parentName="p">{`高橋`}</strong>{`: やはり要件定義フェイズでした。当初の予定より大分伸びてしまいました。これは着地点が不明確なまま、議論が進んでしまっていたのが原因でした。`}</p>
    <p>{`対応として`}<strong parentName="p">{`全員でちゃんとコンセンサスを取りながら、着地点を明確にして要件定義をやり直した`}</strong>{`結果立て直すことができました。要件定義をしっかりやるというのはこの研修が初めてに近い状態だったので、大変でした。`}</p>
    <p>{`当時フワフワとした状態で進めてしまったところが反省点だったので、不明な部分などは自分の中で全部つぶした上で開発するということが大事なんだということが学べました。`}</p>
    <p><strong parentName="p">{`内田`}</strong>{`: 要件定義していた最初の 1 週間は本当に進みが悪くて大変でした。1 週間経ってようやく`}<strong parentName="p">{`「自分達がオーナーシップを取らないと開発が進まない」ということを実感`}</strong>{`したので、そこから勢いが付いた感じです。それまではどこか自分事として捉えられてなかったんだと思います。`}</p>
    <p><strong parentName="p">{`寺内`}</strong>{`: 最初のほうではメンバーの向き不向きとか性格などがやはり分からなかったので、お互いの期待値調整が大変だったなと思います。`}</p>
    <p>{`最初の時点で目的に対する姿勢も、メンバーごとに温度感が違いました。そこで、全員でお互いの得意・不得意などや期待することを、擦り合せる時間を作ってようやく役割分担もできました。`}</p>
    <p>{`これで、ようやくスタートラインに立ってゴールもそこに至る道筋も明確になりました。自分は`}<strong parentName="p">{`今のチームでも上司や同僚に自分の情報を開示しつつ、期待値を把握して仕事をする`}</strong>{`ことによって目指すべき場所が認識できるようになり、仕事が円滑に進むようになった実感があります。`}</p>
    <p><em parentName="p">{`研修については、皆さんそろっての初の共同作業ということもあり、苦労も達成感も感じていますね。
現在の業務にも、きちんと活きているというのは嬉しい限りです。要件定義から自分達でオーナーシップを持って目的を持ってチームで開発をするという、実践的な研修を心がけていたので、ちゃんとそこを経験してもらっているようです。`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5ee35003d7483b9b6d00267a74bc5ffe/cdb2b/image_009.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABqGKZrM4r/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQIDEhMABBExM//aAAgBAQABBQLXkUWpt5GN+bAEld//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8Bmo//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAgEBPwGH/8QAHBAAAQMFAAAAAAAAAAAAAAAAAAECMRAREiFB/9oACAEBAAY/AsnOk7pbEoIOp//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExQcH/2gAIAQEAAT8heRHGb4JyYXbVje/QxcHKVqNT/9oADAMBAAIAAwAAABD3D//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxBJmP/EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QG9R//8QAHBABAAIDAAMAAAAAAAAAAAAAAREhADFhQVGR/9oACAEBAAE/EBMbVoLnzBWbB3QUY9dxZdFNMBSKRHjeKKzsTmVAoz//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image3",
            "title": "image3",
            "src": "/static/5ee35003d7483b9b6d00267a74bc5ffe/e5166/image_009.jpg",
            "srcSet": ["/static/5ee35003d7483b9b6d00267a74bc5ffe/f93b5/image_009.jpg 300w", "/static/5ee35003d7483b9b6d00267a74bc5ffe/b4294/image_009.jpg 600w", "/static/5ee35003d7483b9b6d00267a74bc5ffe/e5166/image_009.jpg 1200w", "/static/5ee35003d7483b9b6d00267a74bc5ffe/cdb2b/image_009.jpg 1281w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`今後について`}</h1>
    <p><em parentName="p">{`最後にメンバーそれぞれの皆さんにこれから目指すエンジニア像を聞いてみました。`}</em></p>
    <p><strong parentName="p">{`佐々岡`}</strong>{`: 今後は`}<strong parentName="p">{`ビジネス側の知見・現場の課題感・プロダクトのあるべき姿という 3 つの視点をきちんと身につけていけるエンジニア`}</strong>{`になっていきたいと思っています。`}</p>
    <p>{`現時点ではまだまだという自覚があるので、これから色々な先輩方の背中を追いかけつつ、成長していきたいと思います。`}</p>
    <p><strong parentName="p">{`高橋`}</strong>{`: 今はプロダクトマネージャやリーダーがプロダクトの根本的な基本要件を考えて、そこから詳細要件を自分達が考えて実装しているのですが、行く行くはそうした`}<strong parentName="p">{`基本要件から自分で考えて、周りと連携しつつ実装していけるようになりたい`}</strong>{`です。`}</p>
    <p>{`ここまで出来るようになると、自分が社会にインパクトを与えるプロダクトを作ったと胸を張って言えるんじゃないかなと思っています。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 将来的には経営者の道を目指そうと思っています。ただ、プロダクト開発をしっかり理解し、一定の技術力を身につけていない状態では、人も付いてこないと思うんです。`}</p>
    <p>{`ですので、`}<strong parentName="p">{`説得力を持てるくらい技術力を身につけていきたい`}</strong>{`と思います。まだ出来てないと自覚しているのですが、自分が勉強させて頂いている部分を、早くチームの方達に恩返しできるようにしてきたいです。`}</p>
    <p><strong parentName="p">{`内田`}</strong>{`: 元々課題解決するプロダクトを作りたいという思いで入社しましたが、実現するためには 3 つやることがあると思っています。
1 つは「解決のために最適解を選択できること」です。これは幅広い技術を身につけたエンジニアになることかなと思っています。`}</p>
    <p>{`2 つ目は「ちゃんとドメイン知識を習得すること」です。最適解を選ぶのにも業務知識が無いままだと絶対に良い選択をできないと考えています。`}</p>
    <p>{`最後は「自分の考えをきちんと推し進められるビジネススキル」です。色々と関わる人にちゃんとコミュニケーションを取って、自分が良いと思うものを広められればと考えています。メドレーは`}<strong parentName="p">{`自分が凄いと思ったエンジニアの方達がたくさんいらっしゃるので、行く行くは自分もそう思われる位になれたら`}</strong>{`…と思います。`}</p>
    <p><strong parentName="p">{`寺内`}</strong>{`: なりたいエンジニア像については、既にみんなに言いたいことを言われているんですが w
直近の目標は、マネージャから見ても「一人前である」と思われる実力を身につけたエンジニアになることです。
また、内側に目を向けられるエンジニアになることも目標です。新機能や定常業務の開発だけではなく、部署内の`}<strong parentName="p">{`開発以外の人達が気持ち良く働けるようになる開発をしていけば、顧客などにも良い影響が出て、結果として事業の成長になる`}</strong>{`のではないかと考えています。`}</p>
    <p>{`ですので、部署の皆さんにも喜んでもらえるような一人前のエンジニアになっていきたいですね。`}</p>
    <p><em parentName="p">{`現在は技術や仕事の仕方などを吸収しながらぐんぐんと成長している皆さんですが、最終的な目標はプロダクトに貢献できるようにという背景を感じます。ちゃんとビジネス面も分かったエンジニアにという考え方を持っていてメドレーでエンジニアに求められる部分を意識しているなと思いました。`}</em></p>
    <h1>{`おわりに`}</h1>
    <p>{`21 年入社エンジニアの座談会はいかがでしたでしょうか。`}</p>
    <p>{`メドレーの新卒研修を受けた側の感想を公開するのは初めての試みでしたが、自分もインタビューをしていて改めて当時メンバーの皆さんが苦悩していた部分や、現在に活きている部分などを聞けて勉強になりました。`}</p>
    <p>{`今年も既に 22 年新卒入社のエンジニアメンバーが研修をしている真っ最中ですが、こうした経験などを後輩に伝えてもらうと、より良い組織になっていくのではないかと思っています。`}</p>
    <p>{`最後に`}<strong parentName="p">{`一緒に医療の未来を作っていく仲間を募集`}</strong>{`しているので、この記事を読んでメドレーに興味が湧いた方はぜひ、話をしましょう!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c9d4dd7efc8f949b6450155a43fb4fb6/cdb2b/image_002.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAF6YXSM1iv/xAAcEAEAAQQDAAAAAAAAAAAAAAADBAABESMxMzT/2gAIAQEAAQUCjpV03yEyx97ekuf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAQQDAAAAAAAAAAAAAAAAAgEDESIxcXKR/9oACAEBAAY/AkUlW1YbGBeTwqdz/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUaH/2gAIAQEAAT8hZcME5gW4EVvc8INaT7cAizuANgcp/9oADAMBAAIAAwAAABCM3//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxCrr//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPxCMf//EAB4QAQACAgIDAQAAAAAAAAAAAAEAESExQVFhcYGh/9oACAEBAAE/ELmBQMOdVzRX7N2uBi2E+7l+wVc7iAKwOn1KhHtI8IdqeCf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "集合写真 2",
            "title": "集合写真 2",
            "src": "/static/c9d4dd7efc8f949b6450155a43fb4fb6/e5166/image_002.jpg",
            "srcSet": ["/static/c9d4dd7efc8f949b6450155a43fb4fb6/f93b5/image_002.jpg 300w", "/static/c9d4dd7efc8f949b6450155a43fb4fb6/b4294/image_002.jpg 600w", "/static/c9d4dd7efc8f949b6450155a43fb4fb6/e5166/image_002.jpg 1200w", "/static/c9d4dd7efc8f949b6450155a43fb4fb6/cdb2b/image_002.jpg 1281w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      